import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { SystemicBisection } from "@/styles/themes/device";
import {
  gnbMobileHeight,
  gnbTabletHeight,
  gnbTopMenuHeight,
} from "@/styles/variables";

const fadeInAndOut = keyframes`
  0% {
    opacity: 0;
  }
  12.5% {
    opacity: 1;
  }
  87.5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const ToastWrapper = styled.div<{
  isVisible: boolean;
  isCenter: boolean;
}>`
  display: flex;
  align-items: ${({ isCenter }) => (isCenter ? "center" : "flex-start")};
  justify-content: center;
  position: fixed;
  top: calc(${gnbMobileHeight}px + 12px);
  left: 0;
  width: 100%;
  height: fit-content;
  animation: ${({ isVisible }) => (isVisible ? fadeInAndOut : "none")} 4s;
  z-index: 1000;
  ${SystemicBisection.Wide} {
    top: calc(${gnbTopMenuHeight}px + ${gnbTabletHeight}px + 16px);
  }
`;

export const ToastContainer = styled.div<{
  isVisible: boolean;
  isCenter: boolean;
}>`
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 20px;
  gap: 4px;
  word-break: keep-all;

  width: fit-content;
  height: fit-content;

  background: rgba(58, 62, 65, 0.8);

  box-shadow: 0px 8px 24px rgba(129, 137, 143, 0.12);
  border-radius: 100px;

  white-space: pre-wrap;
`;

export const ToastText = styled.div`
  text-align: center;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;

  color: #ffffff;

  white-space: pre-wrap;
`;
