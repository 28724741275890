import { CloseBtnSVG, MyPageLogoSVG } from "./Gnb.style.svg";
import styled from "@emotion/styled";
import { useState } from "react";
import { useAtom } from "jotai";
import {
  isMyPageOpenAtom,
  isNavigatorOpenAtom,
} from "@/stores/_layout/gnb";
import { useIsGnbMobile } from "@/businesslogics/_layout/window";
import {
  gnbMobileHeight,
  lnbMobileHeight,
  zIndexLnb,
} from "@/styles/variables";
import { useLnb } from "@/businesslogics/_layout/gnb/v2";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

export const GnbMyPageLogo = () => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isMyPageOpen, setIsMyPageOpen] = useAtom(isMyPageOpenAtom);
  const [, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const isGnbMobile = useIsGnbMobile();
  const lnbData = useLnb();
  return (
    <LogoWrapper
      onMouseEnter={() => {
        if (!isGnbMobile) setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        if (!isGnbMobile) setIsMouseOver(false);
      }}
      onClickCapture={() => {
        if (isGnbMobile) {
          setIsMouseOver(true);
          setTimeout(() => {
            setIsMouseOver(false);
          }, 100);
        }
      }}
      onClick={(e) => {
        setIsNavigatorOpen(false);
        setIsMyPageOpen(!isMyPageOpen);
        e.stopPropagation();
        sendCPLog("scc_gnb_click", {
          button_text: "마이페이지",
        });
      }}
    >
      {isMyPageOpen && isGnbMobile ? (
        <CloseBtnSVG />
      ) : (
        <MyPageLogoSVG active={isMouseOver} />
      )}
      {!!lnbData && lnbData.length >= 5 && <ShadyBox />}
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShadyBox = styled.div`
  position: fixed;
  width: 30px;
  height: ${lnbMobileHeight}px;
  right: 0;
  top: ${gnbMobileHeight}px;
  z-index: ${zIndexLnb + 1};

  background: linear-gradient(270deg, #f7fafb 0%, rgba(247, 250, 251, 0) 100%);
`;
