export const breakpointMobile = 335;
export const breakpointTablet = 758;
export const breakpointDesktop = 1024;

export const accentColor = "#E8344E";
export const primaryColor = "#ffffff";
export const onPrimaryColor = "#000000";
export const purplePrimaryColor = "#7e7bda";

export const black = "#000000";
export const white = "#ffffff";
export const deem = "#d4d4d4";

export const fontWhiteColor = "#ffffff";
export const fontBlackColor = "#212529";

export const blogDefaultGray = "#6b6e72";
export const blogDarkGray = "#505254";
export const blogLightGray = "#a4a6a9";
export const blogAccentInfoTextColor = "#ff4d58";
export const blogAccentTextColor = "#ff4d58";
export const blogLightAccentTextColor = "#ff4d58";
export const blogLightAccentBackgroundColor = "#fbf2f2";
export const blogAccentBackgroundColor = "#ffe5e5";
export const blogLinkTextColor = "#0085ff";

export const maxWidth = 1230;
export const newStartCurriDetailMaxWidth = 1030;
export const curriDetailMaxWidth = 706;
export const modalMaxWidth = 540;
export const curriculumNewSidebarMaxWidth = 272;

export const curriDetailMobilePadding = 24;
export const curriDetailDesktopPadding = 21;

export const animTrans = "all .4s";

export const zIndexFloating = 10;
export const zIndexGnb = 20;
export const zIndexGnbTopMenu = zIndexGnb + 1;
export const zIndexGnbDropDown = zIndexGnb + 1;
export const zIndexLnb = zIndexGnb - 1;
export const zIndexTab = zIndexFloating - 1;
export const zIndexGnbMenu = zIndexGnb + 2;
export const zIndexModalBg = 99;
export const zIndexModal = 100;
export const zIndexChannelTalkFloating = zIndexGnb + 3;
export const zIndexTooltip = zIndexChannelTalkFloating + 1;
export const zIndexSearchPage = zIndexGnb - 1;
export const zIndexFloatingBar = zIndexGnb - 3;

export const navbarMobileHeight = 64;
export const navbarDesktopHeight = 72;
export const navbarHeightWithBanner = 110;
export const lineBannerMobileHeight = 72;
export const lineBannerTabletHeight = 53;
export const gnbTopMenuHeight = 42;
export const gnbMobileHeight = 50;
export const gnbTabletHeight = 60;
export const lnbMobileHeight = 40;
export const lnbTabletHeight = 45;
