import styled from "@emotion/styled";
import { mTitle2, neutralDay, wBody1 } from "@teamsparta/design-system";
import { DisplayResolution } from "@/styles/themes/device";

export const StudyPaymentGnbContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  ${DisplayResolution.TabletAndDesktop} {
    width: 820px;
  }
`;

export const StudyGnbWrapper = styled.div`
  background-color: ${neutralDay.white};
  position: fixed;
  top: 0;
  z-index: 21;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const GnbLeft = styled.div`
  display: flex;
  width: 36px;
  height: 32px;
  padding-left: 16px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
`;

export const GnbTitle = styled.div`
  ${mTitle2};
  color: ${neutralDay.gray100};
  line-height: 22px;
  ${DisplayResolution.TabletAndDesktop} {
    ${wBody1};
    line-height: 26px;
    color: ${neutralDay.gray90};
  }
`;

export const GnbRight = styled.div`
  display: flex;
  width: 36px;
  height: 32px;
  padding-right: 16px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
`;
