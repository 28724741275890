import {
  Device,
  DisplayResolution,
  SystemicDevice,
} from "@/styles/themes/device";
import styled from "@emotion/styled";
import { accentColor, maxWidth } from "@/styles/themes/_variables";
import { fontPretendard } from "@/styles/fonts";
import {
  gnbMobileHeight,
  gnbTabletHeight,
  gnbTopMenuHeight,
  lineBannerMobileHeight,
  lineBannerTabletHeight,
  zIndexLnb,
} from "@/styles/variables";
import { mBody3, neutralDay, wBody1 } from "@teamsparta/design-system";

export const LnbWrapper = styled.div<{
  showLineBanner;
  isMobile;
}>`
  background: rgba(246, 249, 250, 0.9);
  box-shadow: inset 0px -1px 0px #eef3f6;
  backdrop-filter: blur(25px);
  transition: all 0.4s;
  position: ${({ isMobile }) => isMobile && "fixed"};
  width: 100vw;
  z-index: ${zIndexLnb};
  top: ${({ showLineBanner }) =>
    showLineBanner
      ? `${lineBannerMobileHeight + gnbMobileHeight}`
      : gnbMobileHeight - 2}px;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  ${SystemicDevice.Desktop} {
    position: relative;
    top: ${({ showLineBanner }) =>
      showLineBanner
        ? `${lineBannerTabletHeight + gnbTabletHeight + gnbTopMenuHeight}px`
        : `${gnbTabletHeight + gnbTopMenuHeight}px`};
  
`;

export const LnbDiv = styled.div`
  white-space: nowrap;
  display: flex;
  height: 40px;
  max-width: ${maxWidth}px;
  margin: auto;
  width: fit-content;
  justify-content: center;
  flex-direction: column;
  position: relative;

  ${Device.TabletAndDesktop} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: fit-content;
  }
`;

export const LnbUl = styled.ul`
  display: flex;
  padding: 12px;
  gap: 30px;

  ${Device.TabletAndDesktop} {
    width: 400px;
    justify-content: inherit;
    gap: 50px;
  }
`;

export const LnbItem = styled.div`
  flex-grow: 1;
  text-align: center;
  font-family: ${fontPretendard};
  line-height: 1.5;
  font-size: 14px;
  font-weight: ${(props: { active: boolean }) => (props.active ? 700 : 500)};
  color: ${(props: { active: boolean }) =>
    props.active ? "#141617" : `#9DA7AE`};
  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }

  ${Device.TabletAndDesktop} {
    flex-grow: 0;
    font-size: 14px;
  }
`;

export const LnbItemText = styled.div``;

export const LnbIndicator = styled.div`
  display: flex;
  background-color: ${accentColor};
  position: absolute;
  transition: all 0.4s;
  top: 44px;
  height: 4px;
  border-radius: 10px;
  left: ${(props: { left: number; width: number }) => props.left}px;
  width: ${(props: { left: number; width: number }) => props.width}px;

  ${Device.TabletAndDesktop} {
    height: 2px;
    top: 50px;
  }
`;
