import styled from "@emotion/styled";
import {
  animTrans,
  gnbTabletHeight,
  gnbTopMenuHeight,
  lineBannerTabletHeight,
  zIndexGnb,
  zIndexGnbTopMenu,
} from "@/styles/variables";
import { neutralDay } from "@teamsparta/design-system";
import { breakpoints } from "@/styles/themes/device";

export const TopWrapper = styled.div<{ fixed; showLineBanner; show?: boolean }>`
  z-index: ${zIndexGnbTopMenu};
  display: flex;
  //top: 0;
  width: 100%;
  height: ${gnbTopMenuHeight}px;
  justify-content: space-between;
  align-items: center;
  position: ${({ fixed }) => {
    return fixed ? "fixed" : "absolute";
  }};
  border-bottom: ${neutralDay.gray20} 1px solid;
  top: ${({ show }) => (show ? "0" : "-100%")}; // 스크롤에 따라 위치 조정

  transition: ${animTrans};
  background-color: white;

  * {
    word-break: normal;
    white-space: nowrap;
    box-sizing: border-box;

    &:before,
    &:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
`;
export const MainWrapper = styled.div<{
  fixed;
  showLineBanner;
  show?: boolean;
}>`
  z-index: ${zIndexGnb};
  display: flex;
  flex-direction: column;
  transition: ${animTrans};
  background-color: white;
  height: ${({ showLineBanner }) =>
    showLineBanner
      ? `${gnbTabletHeight + lineBannerTabletHeight}px`
      : `${gnbTabletHeight}px`};

  width: 100%;

  border-bottom: ${neutralDay.gray20} 1px solid;
  position: ${({ fixed }) => (fixed ? "fixed" : "absolute")};
  //top: 42px;
  top: ${({ show }) => (show ? "42px" : "-100%")}; // 스크롤에 따라 위치 조정
  // 기존 페이지의 style_old.css override 방지용
  * {
    word-break: normal;
    white-space: nowrap;
    box-sizing: border-box;

    &:before,
    &:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
`;

export const InnerContainer = styled.div`
  z-index: ${zIndexGnb};
  width: 100%;
  height: 34px;
  max-width: 1200px;
  padding: 0 24px;
  margin: 13px auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: nowrap;
  min-width: ${breakpoints.tablet}px;
`;

export const InnerContainerBottom = styled(InnerContainer)`
  margin: 14px auto 12px;
`;

export const CursorWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 6px;
`;
export const ColumnContainer = styled.div`
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Divider = styled.div`
  width: 1px;
  height: 14px;
  background: #c7d2d8;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
