import { useEffect, useRef, useState } from "react";
import { atom, useAtom } from "jotai";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Bisection,
  device,
  DisplayResolution,
  SystemicDeviceWithoutMediaQuery,
} from "@/styles/themes/device";
import { useReadOnlyWindowScrollY } from "../../_common/window/scroll";

export const scrollYAtom = atom(0);

export const useWindowScrollY = () => {
  const [scrollY, setScrollY] = useAtom(scrollYAtom);
  // const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrollY;
};

export const windowSizeAtom = atom({ width: 0, height: 0 });

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useAtom(windowSizeAtom);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isDesktop = useMediaQuery(device.desktop);
  return isDesktop ? "desktop" : "mobile";
};

export const useIsGnbMobile = () => {
  return !useMediaQuery(DisplayResolution.TabletAndDesktop);
};

/** TODO: 온라인 해상도 통일 프로젝트 완료시 파라미터 삭제 및 유관 훅들 정리*/
export const useIsMobile = (isV2 = false) => {
  return !useMediaQuery(
    isV2
      ? SystemicDeviceWithoutMediaQuery.TabletAndDesktop
      : device.tabletAndDesktop,
  );
};

export const useIsLessThanTablet = () => {
  const [, setWindowSize] = useAtom(windowSizeAtom);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return !useMediaQuery(DisplayResolution.TabletAndDesktop);
};

export const useIsDesktop = (isV2 = false) => {
  const [, setWindowSize] = useAtom(windowSizeAtom);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return useMediaQuery(
    isV2 ? SystemicDeviceWithoutMediaQuery.Desktop : device.desktop,
  );
};

export const useIsNarrow = () => {
  const [, setWindowSize] = useAtom(windowSizeAtom);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return !useMediaQuery(Bisection.Wide);
};

export const useScrollUp = (height = 60) => {
  const scrollY = useReadOnlyWindowScrollY();
  const [isScrollUp, setIsScrollUp] = useState(false);
  const lastScroll = useRef(0);
  useEffect(() => {
    if (scrollY > lastScroll.current) {
      setIsScrollUp(false);
    } else {
      setIsScrollUp(true);
    }
    lastScroll.current = scrollY;
  }, [scrollY]);
  return isScrollUp && scrollY > height;
};
