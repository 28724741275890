import { createInstance, HackleProvider } from "@hackler/react-sdk";
import HackleDevTools from "@hackler/javascript-devtools";
import { getDeviceId } from "@/businesslogics/_common/utils/uniqueId";
import { getCookie } from "@/businesslogics/_common/window/cookie";

const Hackle = ({ children }) => {
  let hackleClient;

  const user = {
    id: typeof window !== "undefined" ? getDeviceId() : undefined,
    userId: typeof window !== "undefined" ? getCookie("user_id") : undefined,
    deviceId: typeof window !== "undefined" ? getDeviceId() : undefined,
  };

  const config = {
    debug: false,
    auto_track_page_view: true,
    devTool: HackleDevTools,
    autoOpenDevTool: !process.env.IS_PROD,
  };

  if (process.browser) {
    console.log("go hackle");
    // @ts-ignore
    hackleClient = createInstance(process.env.HACKLE_SDK_KEY, config);
  }

  return hackleClient ? (
    <HackleProvider hackleClient={hackleClient} user={user} supportSSR>
      {children}
    </HackleProvider>
  ) : (
    <>{children}</>
  );
};

export default Hackle;
