"use client";

import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { IN_HOUSE_URL } from "src/models/_common/urls";
import { ON_SALE_URLS } from "./detailPageMap.model";

export function useRouterGuard() {
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    if (!pathname) return null;
    if (pathname.startsWith("/online/")) {
      if (!Object.keys(ON_SALE_URLS).includes(pathname)) {
        alert("판매 중인 상품이 아닙니다.");
        router.replace(IN_HOUSE_URL.상품탐색);
      }
    }
  }, [pathname]);
}
