import styled from "@emotion/styled";
import { zIndexGnbDropDown } from "@/styles/variables";
import { mCaption2, neutralDay, wBody4 } from "@teamsparta/design-system";

export const DropdownContainer = styled.div`
  z-index: ${zIndexGnbDropDown};
  position: absolute;
  top: calc(100%); // 이를 통해 anchor 바로 아래에 위치
  display: flex;
  width: 156px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  left: 10px;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray20};
  background: #fff;
  box-shadow: 0 8px 24px 0 rgba(95, 102, 107, 0.12);
`;

export const LinkContainer = styled.a`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SubTitle = styled.a`
  ${mCaption2};
  color: ${neutralDay.gray70};
`;

export const DropdownItemContainer = styled.a`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const DropdownItem = styled.a`
  cursor: pointer;
  color: #000;
  ${wBody4}
`;

export const Tag = styled.div`
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #dfede4;
  cursor: pointer;
  color: #1f8648;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 15px */
`;

export const Divder = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e4ebf0;
`;
