import styled from "@emotion/styled";
import { Device } from "@/styles/themes/device";

export const NbGuideMobileGnbHeight = 44;
export const NbGuideDesktopGnbHeight = 44;
export const Contents = styled.div`
  padding: 48px 16px;

  ${Device.Mobile} {
    min-height: 100vh;
    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }

  ${Device.TabletAndDesktop} {
    max-width: 708px;
    margin: 0 auto;
    padding: 80px 0;
  }
`;

export const FlexBox = styled.div<{ gap? }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ gap }) => (gap?.mobile ? gap?.mobile : 0)}px;
  ${Device.TabletAndDesktop} {
    justify-content: space-between;
    flex-direction: row;
    gap: ${({ gap }) => (gap?.desktop ? gap?.desktop : 0)}px;
  }
`;

export const HalfColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${Device.TabletAndDesktop} {
    width: 330px;
  }
`;

export const CenterBox = styled.div<{ width? }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  div {
    width: ${({ width }) => (width ? `${width}px` : "100%")};
  }
`;
