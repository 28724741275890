import React from "react";
import {
  useClickAnchor,
  useIsCurrentUrl,
  useOnClickGnbItem,
} from "@/businesslogics/nbGuide";
import {
  nbGuideGnbAnchors,
  nbGuideLogoAnchor,
} from "@/models/nbGuide/gnb.models";
import * as S from "./nbGuideGnb.style";
import {
  CloseBtnSVG,
  HamburgerSVG,
  RightChevronSVG,
} from "src/components/atoms/Icons/nbGuide/nbGuide.svg";
import { SpartaLogo } from "src/components/atoms/Icons/SpartaLogo/SpartaLogo.svg";

const NbGuideGnbMobile = ({ isOpen, setIsOpen }) => {
  const onClickLogo = useClickAnchor(nbGuideLogoAnchor);
  const [isSnbOpen, setIsSnbOpen] = [isOpen, setIsOpen];

  const toggleSnb = () => {
    setIsSnbOpen(!isSnbOpen);
  };
  const makeSnbClose = () => {
    setIsSnbOpen(false);
  };

  return (
    <>
      <Gnb onClickLogo={onClickLogo} makeSnbOpen={toggleSnb} />
      <S.Divider />
      {/* 이벤트 연장 가능성으로 주석처리 */}
      {/*<EventLineBanner />*/}
      <Snb isVisible={isSnbOpen} makeSnbClose={makeSnbClose} />
      <S.OverlayMobile
        isVisible={isSnbOpen}
        onMouseEnter={() => makeSnbClose()}
      />
    </>
  );
};

const Gnb = ({ onClickLogo, makeSnbOpen }) => {
  return (
    <S.GnbContainer>
      <S.LogoContainer onClick={onClickLogo}>
        <SpartaLogo />
        <S.LogoGuideText>국비지원 가이드북</S.LogoGuideText>
      </S.LogoContainer>
      <S.BtnWrapper onClick={() => makeSnbOpen()}>
        <HamburgerSVG />
      </S.BtnWrapper>
    </S.GnbContainer>
  );
};

const Snb = ({ isVisible, makeSnbClose }) => {
  return (
    <S.SnbMobileContainer isVisible={isVisible}>
      <S.SnbMobileHeader>
        <S.BtnWrapper onClick={() => makeSnbClose()}>
          <CloseBtnSVG />
        </S.BtnWrapper>
      </S.SnbMobileHeader>
      <S.Divider />
      <InnerContainer />
    </S.SnbMobileContainer>
  );
};

const InnerContainer = () => {
  const anchors = nbGuideGnbAnchors;

  return (
    <S.InnerContainerMobile>
      {anchors.map((anchor) => {
        return <Row key={`gnb-mobile-${anchor.key}`} anchor={anchor}></Row>;
      })}
    </S.InnerContainerMobile>
  );
};

const Row = ({ anchor }) => {
  return (
    <S.Row>
      <S.RowHeader>
        <GnbAnchor anchor={anchor} isSub={false} />
      </S.RowHeader>
    </S.Row>
  );
};
const GnbAnchor = ({ anchor, isSub }) => {
  const onClickGndItem = useOnClickGnbItem(anchor.href, anchor.name);
  const isAlive = anchor.href ? true : false;
  const isCurrentUrl = useIsCurrentUrl(anchor.href);

  return (
    <S.AnchorMobile
      onClick={onClickGndItem}
      isAlive={isAlive}
      isSub={isSub}
      isCurrentUrl={isCurrentUrl}
    >
      {anchor.name}
      {!isSub && isAlive && <RightChevronSVG />}
    </S.AnchorMobile>
  );
};

export default NbGuideGnbMobile;
