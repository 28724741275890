import {
  HomeSVG,
  MessageSVG,
} from "@/components/atoms/Icons/nbGuide/nbGuide.svg";

type Anchor = {
  key: string;
  name: string;
  href: string;
  subAnchors?: Anchor[];
  icon?: any;
};

const pre = "/nb/guide";

/*
 * GNB
 */
export const nbGuideLogoAnchor: Anchor = {
  key: "logo",
  name: "",
  href: "/nb/guide",
};

const noCardApply: Anchor = {
  key: "noCardApply",
  name: "카드신청",
  href: pre + "/noCard/apply",
};
const noCardReceive: Anchor = {
  key: "noCardReceive",
  name: "카드받기",
  href: pre + "/noCard/receive",
};
const noCard: Anchor = {
  key: "noCard",
  name: "1단계 카드 신청",
  href: pre,
};

const hrdRegFinApply: Anchor = {
  key: "hrdRegFinApply",
  name: "방법 보기",
  href: pre + "/hrdRegFin/apply",
};
const hrRegFinCourses: Anchor = {
  key: "hrRegFinCourses",
  name: "신청하러 가기",
  href: pre + "/hrdRegFin/courses",
};
const hrdRegFin: Anchor = {
  key: "hrdRegFin",
  name: "2단계 카드 발급 및 등록",
  href: pre + "/enroll",
};

const regFinPay: Anchor = {
  key: "regFinPay",
  name: "결제하기",
  href: pre + "/regFin",
};
const regFin: Anchor = {
  key: "regFin",
  name: "3단계 수강신청 및 결제",
  href: pre + "/hrdRegFin",
};

/*
 * FloatingBar
 */
const home: Anchor = {
  key: "home",
  name: "홈",
  href: "/nb/guide",
  icon: HomeSVG,
};
const message: Anchor = {
  key: "message",
  name: "문의하기",
  href: "https://spartacodingclub.channel.io/support-bots/54086",
  icon: MessageSVG,
};

export const nbGuideGnbAnchors: Anchor[] = [noCard, hrdRegFin, regFin];
export const nbGuideFloatingBarAnchors: Anchor[] = [home, message];

// for ABT 193
export const nbGuideGnbBAnchors: Anchor[] = [
  { ...hrdRegFin, ...{ name: "1단계 수강신청" } },
  { ...regFin, ...{ name: "2단계 결제" } },
];
export const nbGuideLogoBAnchor: Anchor = {
  key: "logo",
  name: "main",
  href: "/",
};
