import styled from "@emotion/styled";
import { device, DisplayResolution } from "@/styles/themes/device";
import {
  ActivatableCompProps,
  ShowHideProps,
} from "../../../../templates/_common/common.style";
import { mCaption2, neutralDay } from "@teamsparta/design-system";

export const BusinessInfo = styled.section``;

export const InfoDropDown = styled.h4`
  ${mCaption2};
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${neutralDay.gray60};
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
`;

export const InfoDropDownIcon = styled.img<ActivatableCompProps>`
  width: 18px;
  height: 18px;
  transform: ${(props) => (props.isActive ? "rotate(180deg)" : "rotate(0deg)")};

  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;

export const InfoDetail = styled.dl<ShowHideProps>`
  ${mCaption2};
  font-size: 12px;
  color: ${neutralDay.gray60};
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

  ${DisplayResolution.TabletAndDesktop} {
    display: flex;
    width: 700px;
  }
`;

export const InfoDetailLine = styled.div`
  display: flex;
  flex-direction: row; // 기본적으로 세로 정렬
  gap: 4px;
  @media ${device.desktop} {
    flex-direction: row; // 데스크탑 뷰에서만 가로 정렬
  }
`;

export const InfoDetailLineTitle = styled.dt`
  line-height: 20px;
`;

export const InfoDetailLineValue = styled.dd`
  line-height: 20px;
`;

export const Divider = styled.span`
  display: flex;
  line-height: 20px;
`;
export const InfoDetailLink = styled.a`
  font-size: 12px;
  line-height: 20px;
`;

// export const InfoDetailAfterLine = styled.div`
//   display: none;
//
//   @media ${device.desktop} {
//     display: flex;
//     align-items: center;
// justify-content: center;
//     height: 10px;
//     margin: 0 4px 0 7px;
//     border-right: 1px solid #8b8b8b;
//   }
// `;
