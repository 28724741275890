import React, { useRef } from "react";
import * as S from "./LnbItem.style";
import { useClickAnchor } from "@/businesslogics/_layout/gnb/v2";
import {
  useActiveItemOffsetRight,
  useIsActive,
} from "@/businesslogics/_common/lnb";
import { useRouter } from "next/router";

export const MultiPageLnbItem = ({
  item,
  isEventLnbActive,
}: {
  item: any;
  isEventLnbActive?: any;
}) => {
  const onClickAnchor = useClickAnchor();
  const isActive = useIsActive(item);
  const itemRef = useRef(null);
  useActiveItemOffsetRight(isActive, itemRef);
  const router = useRouter();
  const iscodingland =
    router.pathname === "/codingland" || router.pathname === "/sparthon4";

  return (
    <S.LnbItem
      iscodingland={iscodingland}
      active={isEventLnbActive ?? isActive}
      ref={itemRef}
    >
      <S.LnbItemText onClick={() => onClickAnchor(item)}>
        {item.name}
      </S.LnbItemText>
    </S.LnbItem>
  );
};
