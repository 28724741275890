import React from "react";
import { GnbMobileV3 } from "../../../templates/gnbV3/organisms/Gnb.mobile";
import { useIsGnbMobile } from "@/businesslogics/_layout/window";
import { useMovePage } from "@/businesslogics/_layout/gnb/v2";
import { GnbDesktopV3 } from "../../../templates/gnbV3/organisms/Gnb.desktop";

export const Gnb = () => {
  const isGnbMobile = useIsGnbMobile();
  useMovePage();

  return isGnbMobile ? <GnbMobileV3 /> : <GnbDesktopV3 />;
};
