import styled from "@emotion/styled";
import { neutralDay, scc, wCaption1 } from "@teamsparta/design-system";
import { usePlainLoginV2 } from "@/businesslogics/_common/login";
import { SystemicDevice } from "@/styles/themes/device";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

export const LoginButton = () => {
  const goLoginV2 = usePlainLoginV2();

  const handleLoginButtonClick = () => {
    sendCPLog("scc_gnb_click", {
      button_text: "로그인",
    });
    goLoginV2();
  };

  return (
    <LoginButtonWrapper onClick={handleLoginButtonClick}>
      <LoginButtonText>로그인</LoginButtonText>
    </LoginButtonWrapper>
  );
};

const LoginButtonWrapper = styled.div`
  display: flex;
  height: 32px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid ${scc.red100};
  background: ${neutralDay.white};
  cursor: pointer;
  ${SystemicDevice.TabletAndDesktop} {
    height: 36px;
  }
`;

const LoginButtonText = styled.div`
  ${wCaption1};
  color: ${scc.red100};
  text-align: center;
`;
