import * as CPL from "@teamsparta/cross-platform-logger";

CPL.initCPLog(
  process.env.FLOUNDER_KEY,
  process.env.AMPLITUDE_KEY,
  process.env.HACKLE_SDK_KEY,
  !JSON.parse(process.env.IS_PROD),
);

export const sendCPLog = (key: string, data = {}) => {
  try {
    CPL.sendCPLog(key, data);
  } catch (e) {
    console.log(e);
  }
};

// 홈페이지 view
export const logViewMain = () => {
  sendCPLog("scc_mainPage_view");
};

//커뮤니티 상세페이지 버튼 클릭 로그
export const logClickButton = (button_text: string) => {
  sendCPLog("scc_communityDetailPage_click_button", {
    button_text,
  });
};

//커뮤니티 검색시 검색어
export const logSearchText = (searchText: string) => {
  sendCPLog("scc_communityDetailPage_search", {
    searchText,
  });
};

export const logClickMenu = (menu_text) => {
  sendCPLog("scc_communityPage_click_menu", { menu_text });
};

export const logSearchKeyword = (search_keyword) => {
  sendCPLog("scc_communityPage_search_keyword", { search_keyword });
};

export const logClickCategory = (category_text) => {
  sendCPLog("scc_communityPage_click_category", { category_text });
};

export const logViewPost = (post_text) => {
  sendCPLog("scc_communityPage_view_post", { post_text });
};

export const logClickButtonGNB = (button_text) => {
  sendCPLog("scc_gnb_click_button", { button_text: button_text });
};

export const logViewCommunity = (page_title: string, page_url: string) => {
  sendCPLog("scc_communityPage_view", {
    page_title: page_title,
    page_url: page_url,
  });
};

export const logClickButtonCommunity = (button_text: string) => {
  sendCPLog("scc_communityPage_click_button", { button_text: button_text });
};

export const logClickButtonAiCorrection = (button_text: string) => {
  sendCPLog("scc_communityPage_click_button", { button_text: button_text });
};

export const logViewExhibition = () => {
  sendCPLog("scc_exhibitionPage_view");
};

export const logViewContent = (
  id: string,
  title: string,
  eventId: string,
  template = "general",
  isTrial = false,
) => {
  const detail = {
    course_id: id,
    course_title: title,
    template,
  };

  const logKey =
    template === "nb" ? "nb_mainPage_view" : "scc_courseDetailPage_view";
  sendCPLog(logKey, detail);
};

export const logSubmitCommunityPost = (
  postId: string,
  postTitle: string,
  boardType: string,
  nextHref: string,
  courseTitle: string,
  week: string,
  courseKeyword: string,
) => {
  const postInfos = {
    post_id: postId,
    post_title: postTitle,
    board_type: boardType,
    next_href: nextHref,
    course_title: courseTitle,
    week: week,
    course_keyword: courseKeyword,
  };
  sendCPLog("scc_communityPage_post_completed", postInfos);
};

export const logAddToCart = (
  course_id,
  title,
  label,
  eventId: string,
  isTrial = false,
  extra = {},
) => {
  const detail = {
    course_id,
    title,
    ...extra,
  };
  sendCPLog(
    course_id === "6115cea3e73913245c9538e0"
      ? `nb_mainPage_click_${label}`
      : `scc_courseDetailPage_click_${label}`,
    detail,
  );
};

export const logPageViewB2B = (eventId: string) => {
  sendCPLog(`b2b_mainPage_view`, {
    purpose: "b2bPage_view",
  });
};

export const logClickB2BFloating = (
  eventId: string,
  consultingLocation: string,
) => {
  sendCPLog(`b2b_mainPage_consulting_click`, {
    purpose: "application_button",
    consulting_location: consultingLocation,
  });
};

export const logClickCurriculum = (trackTitle: string, courseTitle: string) => {
  sendCPLog(`b2b_mainPage_curriculum_click`, {
    track_title: trackTitle,
    course_title: courseTitle,
  });
};

export const logApplyB2B = (eventId: string) => {
  sendCPLog(`scc_b2bPage_application_completed`, { purpose: "apply" });
};
export const logViewReviewPage = (eventId: string) => {
  sendCPLog("scc_reviewPage_view_page");
};

export const logClickContact = (
  eventId: string,
  page: string,
  label: string,
) => {
  sendCPLog(`scc_${page}Page_click_${label}`, { purpose: "counsel" });
};

export const logView2022awards = (title) => {
  sendCPLog("scc_community2022awardsPage_view", {
    channel_name: "큰일어워즈",
    title: title,
  });
};

export const logClick2022awards = (button_text) => {
  sendCPLog("scc_community2022awardsPage_click_button", {
    button_text,
  });
};

export const logClickButtonNb = (button_text, button_name, course_title) => {
  sendCPLog("nb_mainPage_courseCard_click", {
    button_text: button_text,
    button_name: button_name,
    course_title: course_title,
  });
};

export const logSearchInCatalog = (search_keyword, result_exist) => {
  sendCPLog("scc_catalogPage_search", {
    search_keyword,
    result_exist,
  });
};
